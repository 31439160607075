export const dictionary = {
	"app.authentication.request.message": "Please, authenticate.",
	"app.title": "BeeBase",
	"app.description": "This is the BeeBase App",
	"app.welcome": "Welcome to BeeBase!",
	"app.bar.title": "BeeBase Admin",
	"app.bar.button.logoff": "Sign Out",
	"app.bar.button.logoff.confirm": "Are you sure you want to sign out?",
	"global.dropdown.defaultItem.placeholder": "<None>",
	"global.date.months.short.0": "Jan",
	"global.date.months.short.1": "Feb",
	"global.date.months.short.2": "Mar",
	"global.date.months.short.3": "Apr",
	"global.date.months.short.4": "May",
	"global.date.months.short.5": "Jun",
	"global.date.months.short.6": "Jul",
	"global.date.months.short.7": "Aug",
	"global.date.months.short.8": "Sep",
	"global.date.months.short.9": "Oct",
	"global.date.months.short.10": "Nov",
	"global.date.months.short.11": "Dec",
	"global.loading": "Loading...",
	"global.logout": "Logout",
	"global.login": "Login",
	"global.list.action.add": "Add",
	"global.list.action.duplicate": "Copy",
	"global.list.action.edit": "Edit",
	"global.list.action.save": "Save",
	"global.list.action.cancel": "Cancel",
	"global.list.action.delete": "Delete",
	"global.list.empty": "No items to show.",
	"global.list.count.empty": "No item",
	"global.list.count.single": "1 item",
	"global.list.count.plural": "${count} items",
	"global.list.filter.placeholder": "To filter, type here..",
	"global.userProfile.button.profile": "See Profile",
	"global.userProfile.button.signOut": "Sign Out",
	"list.categories.name": "Categories",
	"list.products.name": "Products",
	"list.brands.name": "Brands",
	"page.home.title": "BeeBase Admin",
	"page.home.welcome": "Welcome, ${username}.",
	"page.home.dashboard.title": "Company's Infographics",
	"page.login.title": "Login",
	"page.login.photoCredit": "Photo by ${author}",
	"page.login.form.title": "Login",
	"page.login.form.email": "Email",
	"page.login.form.empty.mail": "Please, inform the email.",
	"page.login.form.password": "Password",
	"page.login.form.passwordResetEmailSent": "Password reset email sent.",
	"page.login.form.resetPassword": "I forgot my password",
	"page.login.form.submit": "Sign In",
	"page.login.form.submit.passwordResetEmail": "Send Password Reset mail",
	"page.login.form.submit.error.invalidMail":
		"Invalid email format. Please, verify it.",
	"page.login.form.submit.error.mailNotFound":
		"Email not found. Please, verify it.",
	"page.login.form.submit.error":
		"Invalid user and/or password. Please, verify it and try again.",
	"page.login.form.submit.error.offline":
		"You are offline. Please, reconnect, then try again.",
	"page.categoriesList.title": "Categories",
	"page.clientOrderList.title": "Client Orders",
	"page.clientsList.title": "Clients",
	"page.productsList.title": "Products",
	"page.productsList.action.duplicate.label": "Duplicate of",
	"page.productsList.action.duplicate.confirm.title": "Duplicate Product",
	"page.productsList.action.duplicate.confirm.message":
		"Do you confirm you want to duplicate this product?",
	"page.suppliersList.title": "Suppliers",
	"page.brandsList.title": "Brands",
	"page.landingMails.title": "Mails (Landing Page)",
	"page.stockList.title": "Products Stock",
	"page.home.chat-shortcut": "Initiate Bee AI Chat",
	"page.orderList.items.count": "${count} items.",
	"page.order.internal.pageTitle.new": "New Order",
	"page.order.internal.pageTitle.edit": "View Order",
	"page.order.management.list.empty": "No orders here",
	"page.order.management.order.item.action.idClick":
		"Click to navigate to order details",
	"abbrev.unit": "un",
	"general.notAvailable": "N/A",
	"order.list.status.new":
		"This order was just created. Awaiting customer action.",
	"order.list.status.needsPayment":
		"This order is awaiting payment by the customer.",
	"order.list.status.confirmed":
		"Payment for this order has been confirmed. Processing payment and stock movements.",
	"order.list.status.pending":
		"This order has been paid, confirmed, therefore it is awaiting internal movement.",
	"order.list.status.inProgress":
		"This order is in progress, but has not yet been packaged for shipping.",
	"order.list.status.packaging": "This order is being packaged for shipping.",
	"order.list.status.readyToDeliver":
		"This order is ready for delivery, therefore, it awaits a delivery person.",
	"order.list.status.inDelivery":
		"This order is being delivered by ${deliveryPersonName}.",
	"order.list.status.delivered":
		"This order has been successfully delivered. Awaiting customer confirmation.",
	"order.list.status.completed":
		"This order has been successfully completed.",
	"global.modal.message.action.cancel": "Cancel",
	"global.modal.message.action.confirm": "Ok",
	"global.modal.message.default.title": "Warning",
	"global.button.backToHome": "Back to Home Page",
	"global.button.confirm": "Confirm",
	"global.button.cancel": "Cancel",
	"global.button.save": "Save",
	"global.button.close": "Close",
	"global.confirmCloseWithUnsavedChanges.title": "Confirm",
	"global.confirmCloseWithUnsavedChanges.description":
		"You have unsaved changes. Are you sure you want to cancel?",
	"page.order.internal.checkout.criticalError":
		"Cancelled: Critical error during checkout process. Please, contact support.",
	"page.order.internal.checkout.success": "Order created successfully",
	"page.order.internal.modalTitle.new": "New Order",
	"page.order.internal.modalTitle.edit": "Order ${orderId}",
	"global.you": "You",
	"page.signup.pageTitle": "Your Start with us",
	"page.signup.description":
		"Hello, welcome! Please, sign up to start using our services.",
	"page.signup.formRedirecting": "Redirecting to login page...",
	"dashboard.groups.title": "Company's Infographic",
	"page.stockMovementList.title": "Inventory Movements",
	"page.clientTables.title": "Tables",
	"order.status.new": "New",
	"order.status.needsPayment": "Needs Payment",
	"order.status.confirmed": "Confirmed",
	"order.status.pending": "Pending",
	"order.status.inProgress": "In Progress",
	"order.status.packaging": "Packaging",
	"order.status.tableServed": "Table Served",
	"order.status.readyToDeliver": "Ready to Deliver",
	"order.status.inDelivery": "In Delivery",
	"order.status.delivered": "Delivered",
	"order.status.completed": "Completed",
	"order.status.cancelled": "Cancelled",
	"order.status.rejected": "Rejected",
	"order.status.error": "Error",
	"chart.trend.label": "Trend",
	"chart.newClients.label": "New Clients Count",
	"chart.trend.initialColor": "rgba(255, 206, 86, 1)",
	"chart.trend.successColor": "rgba(75, 192, 192, 1)",
	"chart.trend.negativeColor": "rgba(255, 99, 132, 1)",
	"chart.trend.noChangeColor": "rgba(201, 203, 207, 1)",
	"userProfileForm.formTitle": "My Profile",
	"userProfileForm.sectionTitle": "Personal Information",
	"userProfileForm.propTitles.userName": "Name",
	"userProfileForm.propTitles.userDisplayName": "Preferred Name",
	"userProfileForm.propTitles.userMail": "Email",
	"userProfileForm.propTitles.isAdmin": "Administrator",
	"userProfileForm.ariaLabels.editUserName": "Edit User Name",
	"userProfileForm.ariaLabels.editDisplayName": "Edit Preferred Name",
	"product.enabled": "Enabled",
	"product.enabled.true": "Yes",
	"product.enabled.false": "No",
	"product.enabled.hint":
		"If disabled, the product will not be available for sale.",
	"product.production": "Produced",
	"product.production.true": "Yes",
	"product.production.false": "No",
	"product.production.hint":
		"If item must be produced, it will have commands in the production panel.",
	"product.variations.title": "Variations",
	"product.variations.add": "Add Variation",
	"product.variations.empty": "No variations added.",
	"product.variations.hint":
		"Variations are different versions of the same product.",
	"product.variations.placeholder": "Variation Name",
	"product.additionalItems.title": "Additional Items",
	"product.additionalItems.add": "Add Additional Item",
	"product.additionalItems.empty": "No additional items added.",
	"product.additionalItems.hint":
		"Additional items are optional items that can be added to the product.",
	"product.additionalItems.placeholder": "Additional Item Name",
	"page.companyLinksList.title": "Useful Links",
	"productDetails.title": "Details",
	"productDetails.radio.select": "Select",
	"productDetails.radio.add": "Add",
	"productDetails.label.id": "ID (System)",
	"productDetails.label.name": "Name (Product)",
	"productDetails.label.sku": "SKU (Unique Code)",
	"productDetails.label.category": "Category",
	"productDetails.label.brand": "Brand",
	"productDetails.label.description": "Description",
	"productDetails.label.barCode": "Bar Code",
	"productDetails.input.search": "Search",
	"productDetails.input.add": "Add",
	"productDetails.actions.generateDescription":
		"Generate description using AI (Bee AI)",
	"productDetails.placeholders.name":
		"Example: White T-Shirt Size M, Blue Jeans Size 40.",
	"list.clientTables.busy": "Busy",
	"list.clientTables.free": "Free",
	"productPricingSection.title": "Pricing",
	"productPricingSection.costLabel": "Acquisition Cost (R$)",
	"productPricingSection.transportPercentLabel": "% Transport",
	"productPricingSection.creditCardMarginLabel": "Credit Card Margin",
	"productPricingSection.variableCostMarginLabel": "% Variable Costs",
	"productPricingSection.wagePercentLabel": "% Profit",
	"productPricingSection.minimumPriceLabel": "Minimum Price (R$)",
	"productPricingSection.salePriceLabel": "Sale Price (R$)",
	"productPricingSection.searchAveragePriceButton":
		"Search Average Market Price (Bee AI)",
	"productPricingSection.nameRequiredWarning":
		"Product name is required to use this feature."
};
