import { Timestamp } from "firebase/firestore";
import { ISliceList } from "interfaces/slices";
import { IFirebaseItem } from "interfaces/index";
import { IProductVariations, ProductVariations } from "./ProductVariations";
import {
	IProductAdditionalItems,
	ProductAdditionalItems
} from "./ProductAdditionalItems";

/**
 * Product basic interface.
 */
interface IProduct extends IFirebaseItem {
	name: string;
	description: string;
	price: number;
	images: string[];
	category: string;
	sku: string;
	brand: string;
	sizingHeight: number;
	sizingWidth: number;
	weight: number;
	cost: number;
	suppliers: string[];
	transportPercent: number;
	creditCardMargin: number;
	variableCostMargin: number;
	wagePercent: number;
	enabled: boolean;
	barCode: string;
	production: boolean;
	variations: null | IProductVariations;
	additionalItems: null | IProductAdditionalItems;
}

/**
 * Product Redux slice interface.
 */
interface IProductSlice extends ISliceList<IProduct> {
	inStockIds: {
		[key: string]: string[];
	};
}

/**
 * Product factory class.
 */
class Product implements IProduct {
	id: string = "";
	name: string = "";
	description: string = "";
	price: number = 0;
	images: string[] = [];
	category: string = "";
	deleted: boolean = false;
	timestamp: Timestamp;
	sku: string = "";
	brand: string = "";
	sizingHeight: number = 0;
	sizingWidth: number = 0;
	weight: number = 0;
	cost: number = 0;
	suppliers: string[] = [];
	transportPercent: number = 0;
	creditCardMargin: number = 0;
	variableCostMargin: number = 0;
	wagePercent: number = 0;
	lastChangedBy: string = "";
	enabled: boolean = true;
	barCode: string = "";
	production: boolean = false;
	variations: null | IProductVariations = null;
	additionalItems: null | IProductAdditionalItems = null;

	constructor(data?: undefined | IProduct) {
		this.id = data?.id ?? "";
		this.name = data?.name ?? "";
		this.description = data?.description ?? "";
		this.price = data?.price ?? 0;
		this.images = data?.images ?? [];
		this.category = data?.category ?? "";
		this.deleted = data?.deleted ?? false;
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.sku = data?.sku ?? "";
		this.brand = data?.brand ?? "";
		this.sizingHeight = data?.sizingHeight ?? 0;
		this.sizingWidth = data?.sizingWidth ?? 0;
		this.weight = data?.weight ?? 0;
		this.cost = data?.cost ?? 0;
		this.suppliers = data?.suppliers ?? [];
		this.transportPercent = data?.transportPercent ?? 0;
		this.creditCardMargin = data?.creditCardMargin ?? 0;
		this.variableCostMargin = data?.variableCostMargin ?? 0;
		this.wagePercent = data?.wagePercent ?? 0;
		this.lastChangedBy = data?.lastChangedBy ?? "";
		this.enabled = data?.enabled ?? true;
		this.barCode = data?.barCode ?? "";
		this.production = data?.production ?? false;
		this.variations = data?.variations ?? {};
		this.additionalItems = data?.additionalItems ?? {};
	}
}

/**
 * User profile initial state.
 */
const productsInitialState: IProductSlice = {
	editing: false,
	editingList: [],
	list: [],
	loading: false,
	queried: false,
	inStockIds: {}
	// error: ""
};

export { IProduct, Product, IProductSlice, productsInitialState };
